import './ReasonCard.css';

interface ReasonCardProps {
    reasonName: string,
    reasonImg: string,
    reasonDesc: string
}

function ReasonCard({ reasonName, reasonImg, reasonDesc }: ReasonCardProps) {
    return (
        <div className="col-lg-4 col-12 mt-2 mb-2 mt-lg-0 mb-lg-0">
            <div className="card h-100 panel2Card">
                <div className="card-body">
                    <p className="text-center panel2CardTitle">{reasonName}</p>
                    <div className="w-100 d-flex justify-content-center">
                        <img className='reasonImgControl mb-3 mt-1' src={reasonImg} alt="" />
                    </div>
                    <p className="text-center pb-0 mb-0 panel2CardDesc">{reasonDesc}</p>
                </div>
            </div>
        </div>
    );
}
export default ReasonCard;