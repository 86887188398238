import './ReviewCard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalf } from '@fortawesome/free-solid-svg-icons';

interface ReviewCardProps {
    rating: number,
    reviewOwner: string,
    reviewDesc: string
}

function StarRating({ rating }: { rating: number }) {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;

    const stars = [];
    for (let i = 0; i < fullStars; i++) {
        stars.push(<FontAwesomeIcon icon={faStar} key={i} className='starSetting' />);
    }
    if (hasHalfStar) {
        stars.push(<FontAwesomeIcon icon={faStarHalf} key={stars.length} className='starSetting' />);
    }
    const remainingStars = 5 - stars.length;
    for (let i = 0; i < remainingStars; i++) {
        stars.push(<FontAwesomeIcon icon={faStar} key={stars.length} />);
    }

    return <div className='d-flex justify-content-center'>{stars}</div>;
}

function ReviewCard({ rating, reviewOwner, reviewDesc }: ReviewCardProps) {


    return (
        <div className="col-lg-4 col-12 mt-lg-0 mt-4">
            <div className="card h-100">
                <div className="card-body pt-4 ps-4 pe-4">
                    <StarRating rating={rating} />
                    <h4 className='mt-3'>{reviewOwner}</h4>
                    <p className='mt-4'>{reviewDesc}</p>
                </div>
            </div>
        </div>
    )
}

export default ReviewCard;