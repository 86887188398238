import './Main.css';
import ReasonCard from "./component/ReasonCard/ReasonCard";
import ReviewCard from "./component/ReviewCard/ReviewCard";
import NavBar from "./component/Navbar/Navbar";
import VehicleCard from './component/VehicleCard/VehicleCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faPhoneVolume, faEnvelope, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Helmet } from 'react-helmet'

//Image imports
import banner from "./img/bannerbg.jpeg";
import cancellationIcon from "./img/cancellation.png";
import driverIcon from "./img/driver.png";
import lowCostIcon from "./img/lowcost.png";
import noCardIcon from "./img/nocard.png";
import travelIcon from "./img/travel.png";
import trustIcon from "./img/trust.png";
import logo from './img/batamdrivers.png'

import avanza from "./img/vehicle/1avanza.png";
import innova from "./img/vehicle/1innova.png";
import fourtuner from "./img/vehicle/1Fortuner.png";
import pajero from "./img/vehicle/1pajero.png";
import hiace from "./img/vehicle/1Hi-Ace-commuter.png";
import alphard from "./img/vehicle/1Alphard.png";
import mediumBus from "./img/vehicle/1Medium-Bus.png";
import bigBus from "./img/vehicle/1Big-Bus-45-seats.png";
import { Carousel } from 'react-bootstrap';
import CarouselItem from './component/Carousel/Carousel';
import CarouselComponent from './component/Carousel/Carousel';


function Main() {

    function stickyClick() {
        window.open("https://wa.me/6285264460999", "_blank")
    }

    function tourClick(tourName: string) {
        let msgText = "I would like to book the " + tourName + " package please"
        msgText = msgText.replace(" ", "%20")
        window.open("https://wa.me/6285264460999?text=" + msgText, "_blank")
    }
    return (
        <>
            <Helmet>
                <title>Batam Drivers - Premier Driving Services & Tours in Batam</title>
                <meta name="description" content="Discover Batam with ease! Batam Drivers offers premier driving services for tourists and exciting tour packages around Batam. Explore the beauty of Batam hassle-free with our experienced drivers. Book now!" />
                <meta name="keywords" content="Batam Drivers, Batam tours, Batam driving services, Batam transportation, Batam tour packages, Batam tourism, Batam travel, Indonesia tours" />
                <meta property="og:title" content="Batam Drivers - Premier Driving Services & Tours in Batam" />
                <meta property="og:description" content="Discover Batam with ease! Batam Drivers offers premier driving services for tourists and exciting tour packages around Batam. Explore the beauty of Batam hassle-free with our experienced drivers. Book now!" />
                <meta property="og:url" content="https://batam-drivers-web-production.up.railway.app/" />
                <meta name="language" content="English" />
            </Helmet>
            <NavBar></NavBar>
            <div id="banner" style={{ backgroundImage: `url(${banner})` }} className="w-100 bannerSetting d-flex justify-content-center">
                <div className="textContainer">
                    <p className="bannerLine1 mb-0">Navigate Your Journey</p>
                    <p className="bannerLine2 mt-0">Tailored Car Charters For Your Batam Experience</p>
                </div>
            </div>
            <div id="about" className="w-100 baseDivSetting panel1Setting">
                <h4 className="text-center panelTitle">About Us</h4>
                <p className="mt-lg-5 mt-3">Hi tourists! We provide affordable transport services for your visit to Batam with your family or friends. Enjoy comfort, clean cars, Wi-Fi, and free drinks. Your Satisfaction is Our Priority! Trusted by travelers from Singapore, Malaysia, Taiwan, Hong Kong, Japan, and more. Explore the beauty of Batam with safe transportation. Contact us for details!</p>
                <p className="mt-lg-5 mt-4 mb-0">Best Regards,</p>
                <p className="mt-0 signatureText">Batam Drivers</p>
            </div>
            <div id="" className="w-100 baseDivSetting panel2Setting bg-yellow">
                <h4 className="text-center panelTitle">Why Choose Us</h4>
                <div className="row mt-5">
                    <ReasonCard reasonName={"Booking Without Credit Card"} reasonDesc={"Credit card or deposits are not required to secure your booking with us."} reasonImg={noCardIcon}></ReasonCard>
                    <ReasonCard reasonName={"No Cancellation Fee"} reasonDesc={"You are free to cancel or change the date of service or booking within 12 hours before."} reasonImg={cancellationIcon}></ReasonCard>
                    <ReasonCard reasonName={"Professional Batam Driver"} reasonDesc={"Stay close to our driver all day, learning about Batam's highlights and daily life."} reasonImg={driverIcon}></ReasonCard>
                </div>
                <div className="row mt-lg-4 mt-0">
                    <ReasonCard reasonName={"Official Travel"} reasonDesc={"You are with the right company. We have been travelling for more than 5 Years."} reasonImg={travelIcon}></ReasonCard>
                    <ReasonCard reasonName={"Trusted"} reasonDesc={"We have been serving family tours, groups, corporate agencies and schools."} reasonImg={trustIcon}></ReasonCard>
                    <ReasonCard reasonName={"Affordable Cost"} reasonDesc={"Customize packages to suit your budget without compromising on service quality."} reasonImg={lowCostIcon}></ReasonCard>
                </div>
            </div>
            <div id="vehicle" className="w-100 baseDivSetting panel3Setting bg-black">
                <h4 className="text-center panelTitle">Our Vehicles</h4>
                <div className="row mt-lg-5" id="cardRow">
                    <VehicleCard vehicleImg={avanza} vehicleName={'Toyota Avanza'} vehicleOffer={'Full Day: $60;Half Day: $35;Drop: $10;Include: Driver, Petrol, Parking'}></VehicleCard>
                    <VehicleCard vehicleImg={innova} vehicleName={'Toyota Innova Reborn'} vehicleOffer={'Full Day: $85;Half Day: -;Drop: -;Include: Driver, Petrol, Parking'}></VehicleCard>
                    <VehicleCard vehicleImg={fourtuner} vehicleName={'Toyota Fourtuner'} vehicleOffer={'Full Day: $180;Half Day: -;Drop: -;Include: Driver, Petrol, Parking'}></VehicleCard>
                </div>
                <div className="row" id="cardRow">
                    <VehicleCard vehicleImg={pajero} vehicleName={'Mitsubishi Pajero'} vehicleOffer={'Full Day: $190;Half Day: -;Drop: -;Include: Driver, Petrol, Parking'}></VehicleCard>
                    <VehicleCard vehicleImg={hiace} vehicleName={'Toyota Hiace Commuter'} vehicleOffer={'Full Day: $120;Half Day: $70;1 Way: $35;Include: Driver, Petrol, Parking'}></VehicleCard>
                    <VehicleCard vehicleImg={alphard} vehicleName={'Toyota Alphard'} vehicleOffer={'Full Day: $350;Half Day: -;Drop: -;Include: Driver, Petrol, Parking'}></VehicleCard>
                </div>
                <div className="row" id="cardRow">
                    <VehicleCard vehicleImg={mediumBus} vehicleName={'Medium Bus'} vehicleOffer={'Full Day: $160;1 Way: $80;Drop: -;Include: Driver, Petrol, Parking'}></VehicleCard>
                    <VehicleCard vehicleImg={bigBus} vehicleName={'Big Bus'} vehicleOffer={'Full Day: $230;1 Way: $120;Drop: -;Include: Driver, Petrol, Parking'}></VehicleCard>
                </div>
            </div>
            <div id="packages" className="w-100 baseDivSetting panel4Setting bg-blue">
                <h4 className="text-center panelTitle">Tour Packages</h4>
                <div className="row mt-lg-5 mb-lg-4">
                    <div className="col-lg-4 col-12 mt-lg-0 mt-4">
                        <div className="card tourCard pos-relative" style={{ backgroundImage: "linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('https://deih43ym53wif.cloudfront.net/large_fisabilillah-barelang-bridge-batam-indonesia-shutterstock_1482544481.jpg_2064957538.jpg')" }}>
                            <h4 className="text-center montserratSubrayada400 text-white tourTitle">Day Trip Package</h4>
                            <p className="tourPricing koulen400 text-center p-0 m-0 text-white">SGD -- / PAX</p>
                            <div className="packageDetails w-100 pos-absolute bottom-0 pb-1">
                                <div className="packagePerks ps-5 pe-5 text-white">
                                    <ul>
                                        <li>City Tour Batam</li>
                                        <li>Full Body Massage 90 minutes</li>
                                        <li>2 way ferry ticket include all tax</li>
                                        <li>Include: Lunch</li>
                                        <li>Min. 4 pax</li>
                                    </ul>
                                </div>
                                <div className="w-100 btnWrapper d-flex justify-content-center"><button className="btn btn-whatsapp mb-3" onClick={() => tourClick("City Tour Batam")}>Book via WhatsApp</button></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12 mt-lg-0 mt-4">
                        <div className="card tourCard" style={{ backgroundImage: "linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('https://www.indonesia.travel/content/dam/indtravelrevamp/en/destinations/revisi-2020/batam3.jpg')" }}>
                            <h4 className="text-center montserratSubrayada400 text-white tourTitle">2D1N Package</h4>
                            <p className="tourPricing koulen400 text-center p-0 m-0 text-white">SGD -- / PAX</p>
                            <div className="packageDetails w-100 pos-absolute bottom-0 pb-1">
                                <div className="packagePerks ps-5 pe-5 text-white">
                                    <ul>
                                        <li>City Tour Batam</li>
                                        <li>Full Body Massage 90 minutes</li>
                                        <li>2 way ferry ticket include all tax</li>
                                        <li>Include: Lunch</li>
                                        <li>Min. 4 pax</li>
                                    </ul>
                                </div>
                                <div className="w-100 btnWrapper d-flex justify-content-center"><button className="btn btn-whatsapp mb-3" onClick={() => tourClick("2D1N")}>Book via WhatsApp</button></div>
                            </div>                        </div>
                    </div>
                    <div className="col-lg-4 col-12 mt-lg-0 mt-4">
                        <div className="card tourCard" style={{ backgroundImage: "linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('https://thesmartlocal.com/wp-content/uploads/2022/10/Ranoh-Island-2.jpg')" }}>
                            <h4 className="text-center montserratSubrayada400 text-white tourTitle pb-0 mb-0">Ranoh Island Tour</h4>
                            <p className="tourPricing koulen400 text-center p-0 m-0 text-white">SGD -- / PAX</p>
                            <div className="packageDetails w-100 pos-absolute bottom-0 pb-1">
                                <div className="packagePerks ps-5 pe-5 text-white">
                                    <ul>
                                        <li>City Tour Batam</li>
                                        <li>Full Body Massage 90 minutes</li>
                                        <li>2 way ferry ticket include all tax</li>
                                        <li>Include: Lunch</li>
                                        <li>Min. 4 pax</li>
                                    </ul>
                                </div>
                                <div className="w-100 btnWrapper d-flex justify-content-center"><button className="btn btn-whatsapp mb-3" onClick={() => tourClick("Ranoh Island Tour")}>Book via WhatsApp</button></div>
                            </div>                        </div>
                    </div>
                </div>
            </div >
            <div id="reviews" className="w-100 baseDivSetting panel5Setting bg-yellow">
                <h4 className="text-center panelTitle text-white">Our Customer Feedback</h4>
                <p className="text-center text-white">Don't take our word for it. Trust our customers</p>
                <div className="row mt-lg-5 mb-lg-5">
                    <ReviewCard rating={4.5} reviewOwner={"Haiqal"} reviewDesc={"Had an amazing experience with Kiki! His friendly demeanor made the journey so enjoyable. Punctual, knowledgeable, and full of great suggestions for delicious local food and exciting activities. I couldn't have asked for a better guide in Batam!"}></ReviewCard>
                    <ReviewCard rating={5} reviewOwner={"Jazli"} reviewDesc={"Kiki is the epitome of a perfect driver! Friendly, approachable, and always right on time. He not only knows Batam like the back of his hand but also gave us fantastic recommendations for local eats and must-visit spots. A true gem!"}></ReviewCard>
                    <ReviewCard rating={5} reviewOwner={"Nasri"} reviewDesc={"Kiki is not just a driver; he's your friendly local guide! From being punctual to sharing the best insider tips on where to eat and what to explore, Kiki made our trip memorable. His approachable nature added a personal touch to our Batam adventure. Highly recommended!"}></ReviewCard>
                </div>
            </div>
            <div id="gallery" className="w-100 baseDivSetting panel5Setting bg-black">
                <h4 className="text-center panelTitle text-white">Gallery</h4>
                <p className="text-center text-white">Have a look at our experiences</p>
                <div className="row mt-lg-5 mb-lg-5 d-flex justify-content-center">
                    <div className="col-12 col-lg-6">
                        <CarouselComponent></CarouselComponent>
                    </div>
                </div>
            </div>
            <div className="ps-5 pe-5 pt-5">
                <div className="row d-flex justify-content-between mb-5">
                    <div className="col-lg-5">
                        <h4>BatamDrivers.com</h4>
                        <p>Hi Tourists! We provide affordable transport services for your visit to Batam with your family or friends. Enjoy comfort, clean cars, Wi-Fi, and free Drinks. Your Satisfaction is Our Priority! Trusted by travelers from Singapore, Malaysia, Taiwan, Hong Kong, Japan, and more. Explore the beauty of Batam with safe transportation. Contact us for details!</p>
                        <div id='lgContact' className="row mt-4">
                            <div className="col-lg-12 col-sm-12">
                                <div className="row">
                                    <div className="col-2 d-flex justify-content-center">
                                        <FontAwesomeIcon icon={faPhoneVolume} size="xl" className="align-self-center" />
                                    </div>
                                    <div className="col-10">
                                        <p className='p-0 m-0 montserrat600'>Phone/WhatsApp</p>
                                        <a href="tel:+6285264460999" className='p-0 m-0 footerContact'>+62 852-6446-0999</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 mt-5 mt-lg-0">
                        <h4>About</h4>
                        <ul className='custom-ul ps-0'>
                            <li className='mt-4'><FontAwesomeIcon icon={faChevronRight} className="me-3" /><a className='inter400 footerSitelinkNav' href="#about">About Us</a></li>
                            <li className='mt-4'><FontAwesomeIcon icon={faChevronRight} className="me-3" /><a className='inter400 footerSitelinkNav' href="#vehicle">Our Vehicles</a></li>
                            <li className='mt-4'><FontAwesomeIcon icon={faChevronRight} className="me-3" /><a className='inter400 footerSitelinkNav' href="#packages">Tour Packages</a></li>
                            <li className='mt-4'><FontAwesomeIcon icon={faChevronRight} className="me-3" /><a className='inter400 footerSitelinkNav' href="#reviews">Reviews</a></li>
                            <li className='mt-4'><FontAwesomeIcon icon={faChevronRight} className="me-3" /><a className='inter400 footerSitelinkNav' href="#gallery">Gallery</a></li>
                        </ul>
                    </div>
                </div>
                <div className="row smContact">
                    <div className="col-lg-12 col-sm-12">
                        <div className="row">
                            <div className="col-2 d-flex justify-content-center">
                                <FontAwesomeIcon icon={faPhoneVolume} size="xl" className="align-self-center" />
                            </div>
                            <div className="col-10">
                                <p className='p-0 m-0 montserrat600'>Phone/WhatsApp</p>
                                <a href="tel:+6285264460999" className='p-0 m-0 footerContact'>+62 852-6446-0999</a>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <img src={logo} className='mt-2 mb-4 logoBottom' alt="" />
            </div>
            <button onClick={stickyClick} className="btn btn-whatsapp sticky-button">
                <div className="row w-100">
                    <div className="col-lg-3 col-4 p-0 m-0 ps-2 ps-lg-3 d-flex justify-content-center">
                        <FontAwesomeIcon icon={faWhatsapp} size="2xl" className="align-self-center" />
                    </div>
                    <div className="col-lg-9 col-8 p-0 m-0 d-flex justify-content-center">
                        <div className='align-self-center'>
                            <p className="p-0 m-0 text-start">Contact us directly on</p>
                            <h3 className="p-0 m-0 text-start">WhatsApp</h3>
                        </div>
                    </div>
                </div>
            </button>
        </>
    )
}
export default Main;