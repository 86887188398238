import { Carousel } from 'react-bootstrap';
import './Carousel.css'
import img1 from "./../../img/carousel/img1.jpg"
import img2 from "./../../img/carousel/img2.jpg"
import img3 from "./../../img/carousel/img3.jpg"
import img4 from "./../../img/carousel/img4.jpg"
import img5 from "./../../img/carousel/img5.jpg"

function CarouselComponent() {
    return (
        <Carousel>
            <Carousel.Item interval={1000}>
                <img className="w-100" src={img1} />
            </Carousel.Item>
            <Carousel.Item interval={1000}>
                <img className="w-100" src={img2} />
            </Carousel.Item>
            <Carousel.Item interval={1000}>
                <img className="w-100" src={img3} />
            </Carousel.Item>
            <Carousel.Item interval={1000}>
                <img className="w-100" src={img4} />
            </Carousel.Item>
            <Carousel.Item interval={1000}>
                <img className="w-100" src={img5} />
            </Carousel.Item>
        </Carousel>
    )
}

export default CarouselComponent;