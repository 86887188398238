import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from './../../img/batamdrivers.png'
import './Navbar.css'

function NavBar() {
    return (
        <Navbar className="bg-body-tertiary" collapseOnSelect expand="lg">
            <Container>
                <Navbar.Brand className='pe-0 me-0' href="#">
                    <img src={logo} alt="" width={80} className='ms-2 d-inline-block align-top' />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                    </Nav>
                    <Nav>
                        <Nav.Link href="#about">About Us</Nav.Link>
                        <Nav.Link href="#vehicle">Our Vehicles</Nav.Link>
                        <Nav.Link href="#packages">Tour Packages</Nav.Link>
                        <Nav.Link href="#reviews">Reviews</Nav.Link>
                        <Nav.Link href="#gallery">Gallery</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
export default NavBar;