import './VehicleCard.css'

interface VehicleCardProps {
    vehicleImg: string,
    vehicleName: string,
    vehicleOffer: string
}

function vehicleClick(vehicleName: string) {
    let msgText = "I would like to hire a driver with the " + vehicleName + " vehicle please"
    msgText = msgText.replace(" ", "%20")
    window.open("https://wa.me/6285264460999?text=" + msgText, "_blank")
}

function VehicleCard({ vehicleImg, vehicleName, vehicleOffer }: VehicleCardProps) {
    const offers = vehicleOffer.split(';');

    return (
        <div className="col-lg-4 col-12 ps-0 pe-0 mt-4 mt-lg-0" id="cardContainer">
            <div className="carCard ps-3 pe-3 pt-3 pb-3">
                <div className="cardDetails pb-3">
                    <img src={vehicleImg} alt="" className='vehicleImg mb-3' />
                    <p className="carTitle">{vehicleName}</p>
                    <ul>
                        {offers.map((offer, index) => (
                            <li key={index}>{offer.trim()}</li>
                        ))}
                    </ul>
                    <button className="btn btn-whatsapp mt-3" onClick={() => vehicleClick(vehicleName)}>Book via WhatsApp</button>
                </div>
            </div>
        </div>
    )
}

export default VehicleCard;